import { get } from "lodash";
import { useEffect, useState } from "react";
import s from "./ConfigRenderer.module.scss";
import ReactDOM from "react-dom";
import CopyRenderer from "../CopyRenderer/CopyRenderer";

const ConfigRenderer = ({
    item,
    colName1,
    colName2,
    headerTitle1,
    headerTitle2,
    arrayKeyName,
    firstRenderData,
    eachRowDynamicClass,
    centreAlign = false,
}) => {
    const [showConfigDetails, setShowConfigDetails] = useState(false);
    const [configData, setConfigData] = useState(false);
    const [popupActive, setPopupActive] = useState(false);

    const ConfigDetailPopup = () => {
        useEffect(() => {
            let width = 0;
            document.querySelectorAll(".detailName").forEach(item => {
                if (item.clientWidth > width) width = item.clientWidth;
            });
            document
                .querySelectorAll(".detailName")
                .forEach(
                    item =>
                        (item.style.width = Math.ceil(width / 10) * 10 + "px")
                );
        });

        return (
            <div
                className={s.externalWrap}
                style={{
                    top: configData.topPosition + "px",
                    left: configData.leftPosition + "px",
                }}
                onMouseEnter={() => setPopupActive(true)}
                onMouseLeave={() => {
                    setPopupActive(false);
                    setShowConfigDetails(false);
                }}
            >
                <div className={s.externalHeader}>
                    <div className="detailName">{headerTitle1}</div>
                    <div
                        className={`${s.externalChannel} ${
                            centreAlign ? s.centreAlign : ""
                        }`}
                    >
                        {headerTitle2}
                    </div>
                </div>
                {configData && (
                    <div className={s.externalBody}>
                        {configData?.data?.map((row, index) => {
                            return (
                                <div key={index} className={s.row}>
                                    <div className={`${s.name} detailName`}>
                                        <CopyRenderer>
                                            <p>
                                                {row[colName1]
                                                    ? row[colName1]
                                                    : "-"}
                                            </p>
                                        </CopyRenderer>
                                    </div>
                                    <div
                                        className={`${s.value} ${
                                            s.externalNumber
                                        } ${centreAlign ? s.centreAlign : ""}`}
                                    >
                                        <CopyRenderer>
                                            <p>
                                                {row[colName2]
                                                    ? row[colName2]
                                                    : "-"}
                                            </p>
                                        </CopyRenderer>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    const handleMouseEnter = () => {
        if (item[arrayKeyName]?.length > 1) {
            let topPos = document
                .querySelector(".ab" + item[eachRowDynamicClass])
                ?.getBoundingClientRect()?.top;
            let leftPos = document
                .querySelector(".ab" + item[eachRowDynamicClass])
                ?.getBoundingClientRect()?.left;
            let bottomPos =
                window.innerHeight -
                document
                    .querySelector(".ab" + item[eachRowDynamicClass])
                    ?.getBoundingClientRect()?.bottom;
            setConfigData({
                data: item[arrayKeyName],
                topPosition: bottomPos < 160 ? topPos - bottomPos - 40 : topPos,
                leftPosition: leftPos,
            });
            setShowConfigDetails(true);
        }
    };

    const handleMouseLeave = () => {
        if (!popupActive) {
            setShowConfigDetails(false);
        }
    };

    const getFirstData = () => {
        if (firstRenderData === "colonSeperated") {
            const firstValue = get(
                item,
                `${arrayKeyName}[0][${colName1}]`,
                "-"
            );
            const secondValue = get(
                item,
                `${arrayKeyName}[0][${colName2}]`,
                "-"
            );
            let combinedValue = "-";
            if (firstValue === "-" && secondValue === "-") combinedValue = "-";
            else combinedValue = `${firstValue} : ${secondValue}`;
            return combinedValue;
        } else {
            const firstValue = get(
                item,
                `${arrayKeyName}[0][${colName1}]`,
                "-"
            );
            return firstValue;
        }
    };

    const getLinkClass = () => {
        return item[arrayKeyName]?.length > 1 ? "popUp_link" : "";
    };

    return item[arrayKeyName] === "-" || item[arrayKeyName]?.length === 0 ? (
        "-"
    ) : (
        <div
            className={`ab${item[eachRowDynamicClass]} ${getLinkClass()}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {item[arrayKeyName]?.length > 1 ? (
                <span className="ml-5">{getFirstData()}</span>
            ) : (
                <CopyRenderer>
                    <p>{getFirstData()}</p>
                </CopyRenderer>
            )}
            {item[arrayKeyName]?.length > 1 && (
                <span className={s.details}>
                    +{item[arrayKeyName].length - 1}
                </span>
            )}
            {showConfigDetails &&
                ReactDOM.createPortal(
                    <ConfigDetailPopup />,
                    document.querySelector(".t-body")
                )}
        </div>
    );
};

export default ConfigRenderer;
