import Image from "./Image";
import { getRandomId } from "../../utils/globalUtils";
import style from "./FileUploader.module.scss";
import { useRef } from "react";

const ShowImage = ({ images }) => {
    const imageRef = useRef(null);
    const show = image => {
        return (
            <Image
                key={getRandomId() + image.id}
                image={image}
                imageRef={imageRef}
            />
        );
    };

    return (
        <>
            <div className={style.imageContainer}>{images?.map(show)}</div>
        </>
    );
};

export default ShowImage;
